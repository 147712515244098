@import "https://p.typekit.net/p.css?s=1&k=frx2auv&ht=tk&f=35474.35476.35478&a=193972351&app=typekit&e=css";
:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

figure {
  margin: 0;
}

*, :before, :after {
  box-sizing: border-box;
}

a, button {
  cursor: revert;
}

ol, ul, menu {
  list-style: none;
}

img {
  max-block-size: 100%;
  max-inline-size: 100%;
}

table {
  border-collapse: collapse;
}

input, textarea {
  -webkit-user-select: auto;
}

textarea {
  white-space: revert;
}

meter {
  appearance: revert;
}

:where(pre) {
  all: revert;
}

::placeholder {
  color: unset;
}

::marker {
  content: initial;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

:where([draggable="true"]) {
  -webkit-user-drag: element;
}

:where(dialog:modal) {
  all: revert;
}

@font-face {
  font-family: basic-sans;
  src: url("https://use.typekit.net/af/fa5d28/00000000000000007735abe8/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/fa5d28/00000000000000007735abe8/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/fa5d28/00000000000000007735abe8/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: basic-sans;
  src: url("https://use.typekit.net/af/c68f64/00000000000000007735abe6/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/c68f64/00000000000000007735abe6/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/c68f64/00000000000000007735abe6/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: basic-sans;
  src: url("https://use.typekit.net/af/721f9c/00000000000000007735abf2/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/721f9c/00000000000000007735abf2/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/721f9c/00000000000000007735abf2/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

.tk-basic-sans {
  font-family: basic-sans, sans-serif;
}

:root {
  --navpadding: 10px;
  --bodyPadding: 24px;
  --navHeight: 105px;
  --fontFamily: "basic-sans", sans-serif;
  --fontWeightReg: 400;
  --fontWeightSemi: 600;
  --fontWeightBold: 700;
  --tagcol1: #345995;
  --tagcol2: #f0a733;
  --tagcol3: #e40066;
  --tagcol4: #03cea4;
  --tagcol5: #d92312;
  --colTitle: #010101;
  --colSubTitle: #313031;
  --colBodyTxt: #757575;
}

body {
  font-family: var(--fontFamily);
}

.aui {
  background-color: #fff;
}

.aui__nav {
  height: var(--navHeight);
  padding: 0px var(--navpadding);
  z-index: 10;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.aui__navlogo {
  align-items: center;
  gap: 14px;
  display: flex;
}

.aui__navlogo-clip {
  clip-path: path("M34.03,1.9h0c1.17,1.43,3.17,1.89,4.85,1.11h0c2.97-1.39,6.46.3,7.23,3.48h0c.43,1.8,2.04,3.08,3.89,3.1h0c3.28.04,5.69,3.07,5,6.27h0c-.39,1.81.5,3.66,2.16,4.48h0c2.93,1.46,3.8,5.24,1.79,7.82h0c-1.14,1.46-1.14,3.51,0,4.97h0c2.01,2.59,1.15,6.37-1.79,7.82h0c-1.66.82-2.55,2.67-2.16,4.48h0c.69,3.2-1.73,6.23-5,6.27h0c-1.85.02-3.45,1.3-3.89,3.1h0c-.77,3.18-4.26,4.87-7.23,3.48h0c-1.68-.78-3.68-.33-4.85,1.11h0c-2.07,2.54-5.95,2.54-8.02,0h0c-1.17-1.43-3.17-1.89-4.85-1.11h0c-2.97,1.39-6.46-.3-7.23-3.48h0c-.43-1.8-2.04-3.08-3.89-3.1h0c-3.28-.04-5.69-3.07-5-6.27h0c.39-1.81-.5-3.66-2.16-4.48h0c-2.93-1.46-3.8-5.24-1.79-7.82h0c1.14-1.46,1.14-3.51,0-4.97h0c-2.01-2.59-1.15-6.37,1.79-7.82h0c1.66-.82,2.55-2.67,2.16-4.48h0c-.69-3.2,1.73-6.23,5-6.27h0c1.85-.02,3.45-1.3,3.89-3.1h0c.77-3.18,4.26-4.87,7.23-3.48h0c1.68.78,3.68.33,4.85-1.11h0c2.07-2.54,5.95-2.54,8.02,0Z");
  width: 65px;
  height: 65px;
}

.aui__navlogo-face {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.aui__navlogo-txt {
  width: 120px;
}

.aui__header {
  text-align: center;
  background: linear-gradient(#0000 0%, #dff9fc 20% 70%, #0000 100%);
  align-content: center;
  gap: 20px;
  padding: 120px 30px;
  display: grid;
}

@media (width >= 760px) {
  .aui__header {
    padding: 180px 30px;
  }
}

.aui__herotxt {
  font-size: 2.625rem;
  font-weight: var(--fontWeightBold);
  color: #010101;
  line-height: 3.25rem;
}

.aui__herosubtxt {
  font-size: 1.125rem;
  font-weight: var(--fontWeightReg);
  color: #a4b6ce;
}

@media (width >= 760px) {
  .aui .aui__herotxt {
    font-size: 3.625rem;
    line-height: 4rem;
  }

  .aui__herosubtxt {
    font-size: 1.625rem;
  }
}

.aui__tags {
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  max-width: 450px;
  margin: auto;
  display: flex;
}

.aui__tags .aui__tag {
  color: #fff;
  text-shadow: 1px 1px #0000001a;
  border-radius: 30px;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  font-weight: 600;
  display: flex;
}

.aui__tags .aui__tag:first-child {
  background-color: var(--tagcol1);
}

.aui__tags .aui__tag:nth-child(2) {
  background-color: var(--tagcol2);
}

.aui__tags .aui__tag:nth-child(3) {
  background-color: var(--tagcol3);
}

.aui__tags .aui__tag:nth-child(4) {
  background-color: var(--tagcol4);
}

.aui__tags .aui__tag:nth-child(5) {
  background-color: var(--tagcol5);
}

.aui__main {
  padding: 0px var(--bodyPadding);
  gap: 120px;
  max-width: 1200px;
  margin: 100px auto;
  display: grid;
}

.auiwork {
  grid-template-columns: 1fr;
  align-items: center;
  gap: 50px;
  display: grid;
  position: relative;
}

@media (width >= 760px) {
  .auiwork {
    grid-template-columns: 1fr 1fr;
  }
}

.auiwork__txt {
  text-align: center;
  justify-items: center;
  gap: 12px;
  display: grid;
}

.auiwork__txt-title {
  font-weight: var(--fontWeightBold);
  color: var(--colTitle);
  font-size: 2.25rem;
  display: inline-block;
  position: relative;
}

.auiwork__txt-sub {
  font-weight: var(--fontWeightSemi);
  color: var(--colSubTitle);
  font-size: 1.125rem;
  line-height: 1.9rem;
}

.auiwork__txt-body {
  font-weight: var(--fontWeightReg);
  color: var(--colBodyTxt);
  font-size: 1rem;
  line-height: 1.65rem;
}

@media (width >= 760px) {
  .auiwork__txt {
    text-align: left;
    justify-items: baseline;
  }

  .auiwork__txt .auiwork__txt-title {
    font-size: 2.5rem;
  }

  .auiwork__txt .auiwork__txt-sub {
    font-size: 1.25rem;
  }

  .auiwork__txt .auiwork__txt-body {
    font-size: 1.125rem;
  }
}

.auiwork__herowrap {
  order: -1;
}

.auiwork__herowrap .auiwork__hero {
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  border-radius: 20px;
  max-width: 100%;
  box-shadow: 0 0 20px #0000004d;
}

@media (width >= 760px) {
  .auiwork__herowrap {
    order: 1;
  }
}

.auiwork__ctas {
  justify-content: center;
  gap: 6px;
  margin-top: 10px;
  display: flex;
}

.auiwork__ctas .auiwork__cta, .auiwork__ctas .auiwork__ctagit {
  color: #fff;
  background-color: #7a36ff;
  border-radius: 30px;
  align-items: center;
  height: 40px;
  padding: 0 24px;
  font-size: .875rem;
  font-weight: 600;
  display: flex;
}

.auiwork__ctas .auiwork__cta:hover, .auiwork__ctas .auiwork__ctagit:hover {
  background-color: #5828b6;
}

.auiwork__ctas .auiwork__cta:active, .auiwork__ctas .auiwork__ctagit:active {
  transform: translateY(2px);
}

.auiwork__ctas .auiwork__ctagit {
  border: 2px solid var(--colTitle);
  color: var(--colTitle);
  background-color: #0000;
}

.auiwork__ctas .auiwork__ctagit:hover {
  background-color: #0000000d;
}

@media (width >= 760px) {
  .auiwork__ctas {
    justify-content: flex-start;
  }
}

.auiwork--beta .auiwork__txt-title:after {
  content: "BETA";
  color: #fff;
  background-color: #f0a733;
  padding: 4px;
  font-size: .875rem;
  position: absolute;
  top: -18px;
  right: -31px;
  transform: rotate(6deg);
}

.auiwork--comingsoon {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.auiwork--comingsoon .auiwork__txt, .auiwork--comingsoon .auiwork__herowrap {
  opacity: .125;
}

.auiwork--comingsoon:before {
  content: "Coming Soon, in progress";
  text-align: center;
  font-size: 1.5rem;
  font-weight: var(--fontWeightSemi);
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
/*# sourceMappingURL=index.57ee3913.css.map */
