@import url(_reset.scss);
@import url(_font.scss);

:root {
    --navpadding:10px;
    --bodyPadding:24px;
    --navHeight:105px;
    --fontFamily: "basic-sans", sans-serif;
    --fontWeightReg:400;
    --fontWeightSemi:600;
    --fontWeightBold:700;
    --tagcol1:#345995;
    --tagcol2:#F0A733;
    --tagcol3:#E40066;
    --tagcol4:#03CEA4;
    --tagcol5:#d92312;
    --colTitle:#010101;
    --colSubTitle:#313031;
    --colBodyTxt:#757575;
} 

$mq:760px;

body {
    font-family: var(--fontFamily);
}

.aui {
    $self:&;
    background-color: #fff;

    &__nav {
        width:100%;
        height:var(--navHeight);
        padding:0px var(--navpadding); 
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
    }

    &__navlogo {
        // user-select: none;
        // pointer-events: none;
        display: flex;
        align-items: center;
        gap:14px;

        &-clip {
            
            width:65px;
            height:65px;
            clip-path: path(
                "M34.03,1.9h0c1.17,1.43,3.17,1.89,4.85,1.11h0c2.97-1.39,6.46.3,7.23,3.48h0c.43,1.8,2.04,3.08,3.89,3.1h0c3.28.04,5.69,3.07,5,6.27h0c-.39,1.81.5,3.66,2.16,4.48h0c2.93,1.46,3.8,5.24,1.79,7.82h0c-1.14,1.46-1.14,3.51,0,4.97h0c2.01,2.59,1.15,6.37-1.79,7.82h0c-1.66.82-2.55,2.67-2.16,4.48h0c.69,3.2-1.73,6.23-5,6.27h0c-1.85.02-3.45,1.3-3.89,3.1h0c-.77,3.18-4.26,4.87-7.23,3.48h0c-1.68-.78-3.68-.33-4.85,1.11h0c-2.07,2.54-5.95,2.54-8.02,0h0c-1.17-1.43-3.17-1.89-4.85-1.11h0c-2.97,1.39-6.46-.3-7.23-3.48h0c-.43-1.8-2.04-3.08-3.89-3.1h0c-3.28-.04-5.69-3.07-5-6.27h0c.39-1.81-.5-3.66-2.16-4.48h0c-2.93-1.46-3.8-5.24-1.79-7.82h0c1.14-1.46,1.14-3.51,0-4.97h0c-2.01-2.59-1.15-6.37,1.79-7.82h0c1.66-.82,2.55-2.67,2.16-4.48h0c-.69-3.2,1.73-6.23,5-6.27h0c1.85-.02,3.45-1.3,3.89-3.1h0c.77-3.18,4.26-4.87,7.23-3.48h0c1.68.78,3.68.33,4.85-1.11h0c2.07-2.54,5.95-2.54,8.02,0Z"
              );

        }

        &-face {
            width:100%;
            height:100%;
            object-fit: cover;
        }

        &-txt {
            width:120px;
        }

    }
    

    &__header {
        padding:120px 30px;
        //background-color: sandybrown;
        background: linear-gradient(to bottom, 
        transparent 0%, 
        #DFF9FC 20%, 
        #DFF9FC 70%, 
        transparent 100%);
        text-align: center;
        display: grid;
        gap:20px;
        align-content: center;

        @media (min-width: $mq) { 
            padding:180px 30px;
        }

    }

    
    &__herotxt {
        font-size:2.625rem;
        font-weight: var(--fontWeightBold);
        line-height: 3.25rem;
        color:#010101;
    }

    &__herosubtxt {
        font-size:1.125rem;
        font-weight:  var(--fontWeightReg);        
        color:#A4B6CE;
    }

    @media (min-width: $mq) { 
        
        #{$self}__herotxt {
            font-size:3.625rem;
            line-height: 4rem;
        }

        &__herosubtxt {
            font-size:1.625rem;
        }
        
    }

    &__tags {
        margin:auto;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        gap:10px;
        max-width: 450px;

        #{$self}__tag {
            color:#fff;
            border-radius: 30px;
            height:40px;
            padding:0px 16px;
            display: flex;
            align-items: center;
            font-weight: 600;
            text-shadow:1px 1px 0px rgba(0 0 0 / 10%);

            &:nth-child(1) { background-color: var(--tagcol1); }
            &:nth-child(2) { background-color: var(--tagcol2); }
            &:nth-child(3) { background-color: var(--tagcol3); }
            &:nth-child(4) { background-color: var(--tagcol4); }
            &:nth-child(5) { background-color: var(--tagcol5); }

        }

    }

    &__main {
        padding:0px var(--bodyPadding);
        max-width: 1200px;
        margin: auto;
        display: grid;
        gap:120px;
        margin-top:100px;
        margin-bottom: 100px;
    }
 

    &__footer {

    }


    @media (min-width: $mq) { 
        
        
    }

}

.auiwork {
    $self:&;
    display: grid;
    grid-template-columns: 1fr;
    gap:50px;
    align-items: center;
    position:relative;

    @media (min-width: $mq) { 

        grid-template-columns: 1fr 1fr;

    }

    &__txt {

        display: grid;
        gap:12px;
        text-align: center;
        justify-items: center;

        &-title {
            font-weight: var(--fontWeightBold);
            color:var(--colTitle);
            font-size:2.25rem ;
            position: relative;
            display: inline-block;
        }

        &-sub {
            font-weight: var(--fontWeightSemi);
            color:var(--colSubTitle);
            line-height: 1.9rem;
            font-size:1.125rem ;
        }

        &-body {
            font-weight: var(--fontWeightReg);
            color:var(--colBodyTxt);
            font-size:1rem ;
            line-height: 1.65rem;
        }

        @media (min-width: $mq) { 

            text-align: left;
            justify-items: baseline;

            #{$self}__txt-title {font-size:2.5rem ; }
            #{$self}__txt-sub {font-size:1.25rem ;}
            #{$self}__txt-body {font-size:1.125rem ;}
    
        }
    }

    &__herowrap {
        
        order:-1;

        #{$self}__hero {
            user-select: none;
            pointer-events: none;
            border-radius: 20px;
            box-shadow: 0px 0px 20px rgba(0 0 0 / 30%);
            max-width:100%;
        }

        @media (min-width: $mq) { 

            order:1;
    
        }

    }

    &__ctas {

        display: flex;
        gap:6px;
        margin-top:10px;
        justify-content: center;

        #{$self}__cta, #{$self}__ctagit {
            color:#fff;
            border-radius: 30px;
            height:40px;
            padding:0px 24px;
            display: flex;
            align-items: center; 
            font-weight: 600;     
            font-size:0.875rem;
            background-color: #7A36FF;

            &:hover {
                background-color: #5828b6;
            }

            &:active {
                transform: translateY(2px);
            }

        }

        #{$self}__ctagit {
            border:2px solid var(--colTitle);
            color:var(--colTitle);
            background-color: transparent;

            &:hover {
                background-color: rgba(0 0 0 / 5%);
            }



        }


        @media (min-width: $mq) { 

            justify-content: flex-start;
    
        }

    }

    &--beta {
        #{$self}__txt-title {
            &:after {
                content: "BETA";
                color: #fff;
                background-color: #f0a733;
                padding: 4px;
                font-size: .875rem;
                position: absolute;
                top: -18px;
                right: -31px;
                transform: rotate(6deg);

            }
        }
    }

    &--comingsoon {
        
        pointer-events: none;
        user-select: none;

        #{$self}__txt {
            opacity:0.125;
        }

        #{$self}__herowrap {
            opacity:0.125;
        }


        &:before {
            content:'Coming Soon, in progress';
            position: absolute;
            top:50%;
            right:50%;
            transform: translate(50%, -50%); 
            text-align: center;
            font-size:1.5rem;
            font-weight: var(--fontWeightSemi);


        }

    }

}